.footer-social-container {
  display: flex;
  flex-direction: row;
}

.nav-logo {
  height: 32px;
}

@media screen and (max-width: 768px) {
  .footer-social-container {
    justify-content: center;
  }

  .alert-dismissible {
    width: 80vw;
  }

  .nav-logo {
    height: 24px;
  }
}

.alert-dismissible .close {
  transform: translateY(-5px);
}
.alert-dismissible .close:hover {
  color: white;
}
